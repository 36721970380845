import type { UrlBuilder } from '~/utils/UrlBuilder'
import { removeDependentParams } from './helpers'

export default function (this: UrlBuilder, param: string, value: string) {
  const allParams = this.params
  removeDependentParams({ allParams, param })
  // reset page when filters are changed
  delete allParams.page
  allParams[param] = value
  return this
}
