import type {
  Router,
} from 'vue-router'
import deepmerge from 'deepmerge'
import getSelected from '~/utils/UrlBuilder/getSelected'
import getSelectedValuesCount from '~/utils/UrlBuilder/getSelectedValuesCount'
import isParamInPath from '~/utils/UrlBuilder/isParamInPath'
import isSelected from '~/utils/UrlBuilder/isSelected'
import append from './UrlBuilder/append'
import build from './UrlBuilder/build'
import change from './UrlBuilder/change'
import eject from './UrlBuilder/eject'

export type UrlParams = { [key: string]: string | number | boolean | Array<unknown> }
  & { makes?: string[] }

class UrlBuilder {
  protected params: UrlParams = {}
  static $router: Router
  constructor(readonly routeName: string, params: UrlParams = {}, protected customRouter?: Router) {
    this.params = deepmerge(params, {})
  }

  getParamsCopy() {
    return deepmerge({}, this.params)
  }

  get append() { return append }
  get build() { return build }
  get change() { return change }
  get eject() { return eject }
  get getSelected() { return getSelected }
  get getSelectedValuesCount() { return getSelectedValuesCount }
  get isParamInPath() { return isParamInPath }
  get isSelected() { return isSelected }

  href(): string {
    return this.$router.resolve(this.build()).href
  }

  get $router() {
    if (this.customRouter)
      return this.customRouter
    if (!UrlBuilder.$router)
      UrlBuilder.$router = useRouter()

    return UrlBuilder.$router
  }

  static createCurrentUrlBuilder() {
    const { name, query } = useRoute()
    const params = name === 'search' ? deepmerge({}, useSearchDecodedParams().value) : deepmerge({}, query)
    return new UrlBuilder(name as string, params as UrlParams)
  }

  static createSearchUrl(params: UrlParams) {
    return new UrlBuilder('search', { ...params }).href()
  }
}

class ImmutableUrlBuilder extends UrlBuilder {
  constructor(routeName: string, params: UrlParams = {}) {
    super(routeName, params)
    this.params = new Proxy(this.params, {
      set() {
        throw new Error(
          'Params object cannot be modified in ImmutableUrlBuilder. \n'
          + 'To modify params, create a new UrlBuilder instance with the desired params.',
        )
      },
    })
  }
}

export { ImmutableUrlBuilder, UrlBuilder }
