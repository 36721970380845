import type { FacetValue } from '~/types/CarstoryApiTypes'
import type { UrlBuilder, UrlParams } from '~/utils/UrlBuilder'

export default function eject(this: UrlBuilder, key: string, value: string = '', other: Partial<FacetValue> = {}) {
  if (key === 'make')
    return ejectMake.call(this, value)
  else if (key === 'model')
    return ejectModel.call(this, other.make as string, value)
  else if (key === 'trim')
    return ejectTrim.call(this, other.make as string, other.model as string, value)
  else
    return ejectOther.call(this, key, value)
}

function ejectOther(this: UrlBuilder, key: string, value: string) {
  const allParams = this.params
  const currentValue = allParams[key]

  // reset page when filters are changed
  delete allParams.page

  if (typeof currentValue === 'string' && currentValue.includes(',') && value !== '') {
    const values = value.split(',')
    allParams[key] = currentValue
      .split(',')
      .filter(i => !values.includes(i))
      .join(',')
  }
  else {
    delete allParams[key]
  }

  return this
}

function ejectMake(this: UrlBuilder, make: string) {
  let params = this.params
  let mmts = getMakeModelTrims(params)
  mmts = mmts
    .filter((param) => {
      const [makeValue] = param.split(',')
      return makeValue !== make
    })
  params = position(mmts, params)

  resetPage(params)
  return this
}

function ejectModel(this: UrlBuilder, make: string, model: string) {
  let params = this.params

  let mmts = getMakeModelTrims(params)
  mmts = removeModels({ mmts, make, model })
  params = position(mmts, params)

  resetPage(params)

  return this
}

function ejectTrim(this: UrlBuilder, make: string, model: string, trim: string) {
  let params = this.params

  let mmts = getMakeModelTrims(params)
  mmts = removeTrim({ mmts, make, model, trim })
  params = position(mmts, params)

  resetPage(params)

  return this
}

function removeModels({ mmts, make, model }: { mmts: string[], make: string, model: string }) {
  const makeModelToEject = `${make},${model}`
  return mmts
    .map((value, i) => {
      const [make, model, trim] = mmts[i].split(',')
      const makeModelValue = `${make},${model}`
      const newValue = [make, model, trim]
      const otherMmts = mmts.slice()
      otherMmts.splice(i, 1)
      const sameMakeProcessed = !!findSameMake({ mmts: otherMmts, make })
      const isEjectModel = makeModelValue === makeModelToEject
      if (isEjectModel)
        newValue.splice(1, newValue.length)
      if (sameMakeProcessed && isEjectModel)
        return false
      return newValue?.filter(x => x).join(',')
    })
    .filter(x => x) as string[]
}

function removeTrim({ mmts, make, model, trim }: { mmts: string[], make: string, model: string, trim: string }) {
  const mmtToEject = `${make},${model},${trim}`
  return mmts
    .map((value, i) => {
      const [make, model, trim] = value.split(',')
      const newValue = [make, model, trim]
      const mmtsWithoutCurrentItem = mmts.slice()
      mmtsWithoutCurrentItem.splice(i, 1)
      const sameMakeModelExists = !!findSameMakeModelCombination({ mmts: mmtsWithoutCurrentItem, make, model })
      const isEjectMmt = value === mmtToEject
      if (isEjectMmt)
        newValue.pop()
      if (sameMakeModelExists && isEjectMmt)
        return false
      return newValue?.filter(x => x).join(',')
    })
    .filter(x => x) as string[]
}

function findSameMake({ mmts, make }: { mmts: string[], make: string }) {
  return mmts
    .find((val) => {
      const [makeVal] = val.split(',')
      return make === makeVal
    })
}

function findSameMakeModelCombination({ mmts, make, model }: { mmts: string[], make: string, model: string }) {
  return mmts
    .find((val) => {
      const [makeVal, modelVal] = val.split(',')
      return make === makeVal && model === modelVal
    })
}

function position(mmts: string[], params: UrlParams) {
  if (mmts.length > 0) {
    const [make, model, trim] = mmts[0].split(',')
    params.make = make
    params.model = model
    params.trim = trim
  }
  else {
    delete params.make
    delete params.model
    delete params.trim
  }
  const otherMmts = mmts?.slice(1, mmts.length)
  if (otherMmts)
    params.makes = otherMmts
  return params
}

function getMakeModelTrims(params: UrlParams) {
  const mmts = []
  if (params.make) {
    const mmt = [params.make, params.model, params.trim]
      .filter(x => x)
      .join(',')
    mmts.push(mmt)
  }
  (params.makes as string[])
    ?.forEach(x => mmts.push(x))
  return mmts
}

function resetPage(params: UrlParams) {
  delete params.page
  return params
}
