import type { RouteLocationNamedRaw } from 'vue-router'
import type { UrlBuilder, UrlParams } from '../UrlBuilder'
import encode from '~/shared/encode'
import { getPathParams } from './helpers'

function encodeParams(params: UrlParams) {
  return Object.keys(params)
    .filter(p => params[p])
    .reduce((obj, key) => {
      if (Array.isArray(params[key]))
        return { ...obj, [key]: encodeArray(params[key] as Array<unknown>) }
      else if (key === 'vin')
        return { ...obj, [key]: String(params[key]).toUpperCase() }
      else if (key === 'location' && String(params[key])?.includes(','))
        return { ...obj, [key]: encode(params[key]) }
      else if (key === 'search')
        return { ...obj, [key]: String(params[key]) }
      else if (key === 'dealer_id')
        return { ...obj, [key]: String(params[key]) }
      else
        return { ...obj, [key]: encodeString(params[key]) }
    }, {})
}

function positionPathParams(encodedParams: UrlParams, params: UrlParams, pathParams: Array<string>) {
  return Object
    .keys(params)
    .filter(p => pathParams.includes(p))
    .reduce((acc, p) => ({ ...acc, [p]: encodedParams[p] }), {})
}

export default function build(this: UrlBuilder): RouteLocationNamedRaw {
  const encodedParams = encodeParams(this.params) as UrlParams
  const pathParams = getPathParams(this.routeName, this.$router)
  const sortQueryParamValues = (v: unknown) => (typeof v === 'string') ? v.split(',').sort().join(',') : v
  return {
    name: this.routeName,
    // filter query params using pathParams list
    query: Object.keys(encodedParams)
      .sort()
      .filter(p => !pathParams.includes(p))
      .reduce((obj, p) => ({ ...obj, [p]: sortQueryParamValues(encodedParams[p]) }), {}),
    params: positionPathParams(encodedParams, this.params, pathParams),
  }
}

function encodeString(value: unknown) {
  if (typeof value === 'string' && value.includes(',')) {
    return value
      .split(',')
      .map(p => encode(p))
      .join(',')
  }
  return encode(value)
}

function encodeArray(value: Array<unknown>) {
  return value
    .map((p) => {
      if (typeof p === 'string' && p.includes(',')) {
        return p
          .split(',')
          .map(item => encode(item))
          .join(',')
      }
      return encode(p)
    })
}
