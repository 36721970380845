import type { UrlBuilder } from '../UrlBuilder'
import type { FacetValue } from '~/types/CarstoryApiTypes'

export default function (this: UrlBuilder, key: string, value: string, other: Partial<FacetValue> = {}) {
  if (key === 'make') {
    appendMake.call(this, value)
  }
  else if (key === 'model') {
    appendModel.call(this, { make: other.make!, model: value })
  }
  else if (key === 'trim') {
    appendTrim.call(this, { make: other.make as string, model: other.model as string, trim: value })
  }
  else {
    let paramValue = this.params[key] as string
    paramValue = paramValue
      ? paramValue
        .split(',')
        .concat(value)
        .join(',')
      : value

    // reset page when filters are changed
    delete this.params.page
    this.params[key] = paramValue
  }
  return this
}

function appendMake(this: UrlBuilder, make: string) {
  const params = this.params
  const isMakePathParamOccupied = !!params.make
  if (isMakePathParamOccupied) {
    if (Array.isArray(params.makes)) {
      params.makes.push(make)
    }
    else {
      params.makes = [make]
    }
  }
  else {
    params.make = make
  }
}

function appendModel(this: UrlBuilder, { make, model }: { make: string, model: string }) {
  const params = this.params
  const isPathModelUndefined = !params.model
  const shouldBeInPath = isPathModelUndefined && params.make === make
  let shouldCreateNewMakesEntry = true

  if (shouldBeInPath) {
    params.model = model
    shouldCreateNewMakesEntry = false
  }
  else if (params.makes) {
    params.makes = (params.makes as string[])
      .map((param: string) => {
        const [makeValue, modelValue] = param.split(',')
        const isModelUndefined = !modelValue
        const modelBelongsToMake = makeValue === make
        if (isModelUndefined && modelBelongsToMake) {
          shouldCreateNewMakesEntry = false
          return `${param},${model}`
        }
        else {
          return param
        }
      })
  }

  if (shouldCreateNewMakesEntry) {
    const makesEntry = `${make},${model}`
    if (Array.isArray(params.makes)) {
      params.makes.push(makesEntry)
    }
    else {
      params.makes = [makesEntry]
    }
  }
}

function appendTrim(this: UrlBuilder, { make, model, trim }: { make: string, model: string, trim: string }) {
  const params = this.params
  const isPathTrimUndefined = !params.trim
  const shouldBeInPath = isPathTrimUndefined && params.make === make && params.model === model
  let shouldCreateNewMakesEntry = true

  if (shouldBeInPath) {
    params.trim = trim
    shouldCreateNewMakesEntry = false
  }
  else if (params.makes) {
    params.makes = (params.makes as string[])
      .map((param: string) => {
        const [makeValue, modelValue, trimValue] = param.split(',')
        const isTrimUndefined = !trimValue
        const trimBelongsToMakeModel = makeValue === make && modelValue === model
        if (isTrimUndefined && trimBelongsToMakeModel) {
          shouldCreateNewMakesEntry = false
          return `${param},${trim}`
        }
        else {
          return param
        }
      })
  }

  if (shouldCreateNewMakesEntry) {
    const makesEntry = `${make},${model},${trim}`
    if (Array.isArray(params.makes)) {
      params.makes.push(makesEntry)
    }
    else {
      params.makes = [makesEntry]
    }
  }
}
