import type { UrlBuilder } from '~/utils/UrlBuilder.js'

function countMake(this: UrlBuilder) {
  const selectedMakes = this.getSelected('make', this.params)
  return selectedMakes.length
}

function countModel(this: UrlBuilder) {
  const selectedModels = this.getSelected('model', this.params)
  return selectedModels.length
}

function countTrim(this: UrlBuilder) {
  const selectedTrims = this.getSelected('trim', this.params)
  return selectedTrims.length
}

export default function getSelectedValuesCount(this: UrlBuilder, key: string) {
  if (key === 'make')
    return countMake.call(this)
  else if (key === 'model')
    return countModel.call(this)
  else if (key === 'trim')
    return countTrim.call(this)

  const value = this.params[key]
  if (typeof value === 'string')
    return value.split(',').length

  return 0
}
