import type { UrlBuilder, UrlParams } from '~/utils/UrlBuilder'

const indexMap = {
  make: 0,
  model: 1,
  trim: 2,
}

const getSelectedMakes = function (params: UrlParams) {
  return [params.make]
    .concat((params.makes as string[])?.map(val => val.split(',')[indexMap.make]))
    .filter(x => x)
    .reduce((acc, item) => {
      if (!(acc as string[]).includes(item as string))
        (acc as string[]).push(item as string)

      return acc
    }, [])
}

const getSelectedModels = function (params: UrlParams) {
  const models = params.make && params.model
    ? [`${params.make},${params.model}`]
    : []
  return models
    .concat((params?.makes as string[])?.filter(val => val.split(',')[indexMap.model]))
    .filter(x => x)
    .reduce((acc, item) => {
      const [make, model] = item.split(',')
      const newItem = { id: model, make }
      if (!acc.some(accItem => isEqual(accItem, newItem)))
        acc.push(newItem)

      return acc
    }, [] as { id: string, make: string }[])
}

const getSelectedTrims = function (params: UrlParams) {
  const trims = params.make && params.model && params.trim
    ? [`${params.make},${params.model},${params.trim}`]
    : []
  return trims
    .concat((params?.makes as string[])?.filter(val => val.split(',')[indexMap.trim]))
    .filter(x => x)
    .reduce((acc, item) => {
      const [make, model, trim] = item.split(',')
      const newItem = { id: trim, make, model }
      if (!acc.some(accItem => isEqual(accItem, newItem)))
        acc.push(newItem)

      return acc
    }, [] as { id: string, make: string, model: string }[])
}

export default function getSelected(
  this: UrlBuilder,
  key: string,
  params = { ...this.params } as UrlParams,
) {
  if (key === 'make')
    return getSelectedMakes(params) as string[]
  else if (key === 'model')
    return getSelectedModels(params) as { id: string, make: string }[]
  else if (key === 'trim')
    return getSelectedTrims(params) as { id: string, make: string, model: string }[]

  return ((params[key] || '') as string).split(',') as string[]
}

function isEqual(a: { id: string, make: string, model?: string }, b: { id: string, make: string, model?: string }) {
  return a.id === b.id && a.make === b.make && a.model === b.model
}
