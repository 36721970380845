import type { Ref } from 'vue'
import type { RouteLocationNormalized } from 'vue-router'
// import { LRUCache } from 'lru-cache'
import { FetchError } from 'ofetch'
import { hash as ohash } from 'ohash'
import type { SapiAttributes, SapiListings } from '~/types/CarstoryApiTypes'

export type DecodedParams = Record<string, string | string[]>

const apiBaseUrl = '/'

// const promiseCache = new LRUCache({
//   max: 500,
//   ttl: 20000, // 20 seconds
// })

async function _fetchDataFromSAPI(url: string, params: Record<string, any> = {}) {
  const signal = params.signal
  delete params.signal
  return await $fetch(url, {
    baseURL: `${apiBaseUrl}`,
    signal,
    params,
  })
}

export async function fetchSAPI(url: string, params: Record<string, unknown> = {}) {
  const hash = ohash([url, params])

  const state = useState<any>(hash, () => null)
  if (!state.value) {
    state.value = await _fetchDataFromSAPI(url, params)
  }
  return state.value
}

export async function fetchSearchAttributes(params: object): Promise<SapiAttributes> {
  const path = '/api/attributes/search-filters'
  return await fetchSAPI(path, { ...params })
}

export async function fetchSearchListings(params: object): Promise<SapiListings> {
  const path = '/api/listings/search-results'
  return await fetchSAPI(path, { ...params })
}

export async function decodeParams(route: RouteLocationNormalized): Promise<DecodedParams> {
  const path = '/api/decode-params'
  return await fetchSAPI(path, { ...route.params, ...route.query })
}

export async function fetchSearchData(route: RouteLocationNormalized) {
  const nuxtApp = useNuxtApp()

  const abortControllerState = useState<AbortController | false>('abort', () => false)

  if (abortControllerState.value) {
    abortControllerState.value.abort('New request initiated')
    abortControllerState.value = false
  }

  const loading = useState<boolean>('searchDataLoading', () => false)

  const abortController = new AbortController()
  if (import.meta.client)
    abortControllerState.value = abortController

  const signal = abortController.signal

  const params = {
    ...route.params,
    ...route.query,
    signal,
  }

  const attributesState = useSearchAttributes()
  const listingsState = useSearchListings()
  const decodedParamsState = useState('srpDecodedParams')

  try {
    nuxtApp.callHook('search-data:loading').catch()
    loading.value = true
    const [listings, attributes, decodedParams] = await Promise.all([
      fetchSearchListings(params),
      fetchSearchAttributes(params),
      decodeParams(route),
    ])

    attributesState.value = attributes
    listingsState.value = listings
    decodedParamsState.value = decodedParams

    abortControllerState.value = false
    nuxtApp.callHook('search-data:loaded').catch()
  }
  catch (e: unknown) {
    const isAbortedOnPurpose: boolean = e instanceof FetchError
      && !!String(e.cause).match('New request initiated')

    if (!isAbortedOnPurpose) {
      loading.value = false
      console.log('error fetching search data', e)
      throw e
    }
  }
  finally {
    loading.value = false
  }
  return {
    loading,
  }
}

export function useSearchAttributes(): Ref<SapiAttributes> {
  return useState('searchAttributes')
}

export function useSearchListings(): Ref<SapiListings> {
  return useState('searchListings')
}

export function useSearchDecodedParams(): Ref<DecodedParams> {
  return useState('srpDecodedParams')
}

export function useFiltersOpened(): Ref<boolean> {
  return useState('filtersOpened', () => false)
}
