'use strict'

export default (param: unknown): string => {
  return `${param}`
    .toString()
    .trim()
    .replace(/\s+|[/.*:]|--|\\t/g, '-')
    .replace(/,/g, '_')
    .replace(/&/g, 'and')
    .replace(/['\\()®™#]/g, '')
    .replace(/-+/g, '-') // dedupe '-' character
    .replace(/_-/g, '-') // dedupe '-' character
    .replace(/-$/g, '') // trim '-' at the end
    .toLowerCase()
}
