import type { Router } from 'vue-router'
import type { UrlParams } from '~/utils/UrlBuilder'

const RoutePathParams = new Map<string, Array<string>>()
export function getPathParams(routeName: string, router: Router) {
  if (!RoutePathParams.has(routeName)) {
    const routes = router.getRoutes()
    const foundRoute = routes.find(
      route => route.name === routeName || route.children?.some(child => child.name === routeName),
    )

    if (foundRoute === undefined)
      throw new Error(`Route ${routeName} not found`)

    const pageObj = foundRoute.name === routeName
      ? foundRoute
      : foundRoute.children.find(route => route.name === routeName)
    const extractedPathParams = pageObj!.path.match(/:\w+/g) || []
    RoutePathParams.set(routeName, extractedPathParams.map(p => p.replace(':', '')))
  }
  return RoutePathParams.get(routeName)!
}

export function removeDependentParams({ allParams, param }: { allParams: UrlParams, param: string }) {
  if (param === 'make') {
    delete allParams.model
    delete allParams.trim
  }

  if (param === 'model')
    delete allParams.trim
}
