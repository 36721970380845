import type { UrlBuilder } from '~/utils/UrlBuilder'

const checkIfSrpPathParamIsSelected = function (this: UrlBuilder, { key, value, other }: { key: string, value: string, other: Record<string, string> }) {
  const keyIsMakeModelOrTrim = ['make', 'model', 'trim'].includes(key)
  if (keyIsMakeModelOrTrim) {
    const selectedValues = this.getSelected(key)
    if (key === 'model') {
      return (selectedValues as { id: string, make: string }[])
        .find(x => x.id === value && x.make === other.make)
    }
    else if (key === 'trim') {
      return (selectedValues as { id: string, make: string, model: string }[])
        .find(x => x.id === value && x.make === other.make && x.model === other.model)
    }
    else {
      return (selectedValues as string[]).includes(value)
    }
  }
}

export default function (this: UrlBuilder, key: string, value: string, other: Record<string, string>) {
  const params = this.params
  const isSrpPage = this.routeName === 'search'
  const isParamInSrpPath = isSrpPage && this.isParamInPath(key)

  if (isParamInSrpPath)
    return !!checkIfSrpPathParamIsSelected.call(this, { key, value, other })

  const keyNotFound = !params[key]
  if (keyNotFound)
    return false

  return (params[key] as string)
    .split(',')
    .includes(value)
}
